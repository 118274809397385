<template>
  <callback-panel
    title="Registration successful"
    :subTitle="'The link will expire on: ' + expirationDate"
    message="We have sent you an activation email. If you don't see it in your inbox after a few minutes, please check your spam folder."
    type="success"
    redirect-to="/login"
    button-title="Go to login"
  />
</template>

<script>
import CallbackPanel from "../layouts/components/CallbackPanel.vue";

export default {
  components: {
    CallbackPanel,
  },
  computed: {
    expirationDate() {
      return this.$dateHelpers.addDays(new Date(), 2).toLocaleString();
    },
  },
};
</script>

<style scoped></style>
